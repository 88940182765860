import h from './Header.module.css'
// import Search from '../../img/icons/search.png'
import Cart from '../../img/icons/shopping-cart.png'
import {useAppSelector} from "../../redux/hooks";
import {NavLink} from "react-router-dom";

const Header = () => {
    const shoppingItems = useAppSelector(state => state.shoppingBag.items)

    return (
        <div className={h.Main}>
            <div className={h.Top}>
                <div className={h.LeftBox}>
                    +7 900 444-33-88
                </div>
                <NavLink to='/' className={h.LogoBoxBig}>
                    SNEAKER &nbsp;&nbsp; SHOP
                </NavLink>
                <NavLink to='/' className={h.LogoBoxSmall}>
                    SNEAKER SHOP
                </NavLink>
                <div className={h.RightBox}>
                    {/*<div className={h.SearchBox}>*/}
                    {/*    <button className={h.BtnSearch}>*/}
                    {/*        <img height='28px' src={Search} alt=""/>*/}
                    {/*    </button>*/}
                    {/*    <input type="text" className={h.InputSearch} placeholder="Поиск..."/>*/}
                    {/*</div>*/}
                    <NavLink className={h.Cart} to='/shopping-cart'>
                        <img className={h.CartImage} src={Cart} alt=""/>
                        {
                            shoppingItems.length > 0 &&
                            <span className={h.CartCount}>{shoppingItems.length}</span>
                        }
                    </NavLink>
                </div>
            </div>
            <div className={h.HeaderBottom}>
                <button className={h.Button}>
                    <NavLink style={{ color: "#4f4f4f" }} to='/'>
                        Товары
                    </NavLink>
                </button>
                <button className={h.ButtonDisabled}>
                    Оплата
                </button>
                <button className={h.ButtonDisabled}>
                    Доставка
                </button>
                <button className={h.ButtonDisabled}>
                    Возврат
                </button>
                <button className={h.ButtonDisabled}>
                    Контакты
                </button>
            </div>
        </div>
    );
};

export default Header;