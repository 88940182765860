import './App.css';
import Header from './components/Header/Header';
import Body from "./components/Body/Body";
import Footer from "./components/Footer/Footer";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom"
import ShoppingCart from "./components/Body/ShoppingCart/ShoppingCart";
import 'react-toastify/dist/ReactToastify.css';
import {useAppDispatch} from "./redux/hooks";
import {clearItems, concatItems} from "./redux/reduxComponents/shoppingBag";
// import Contacts from "./components/Body/Contacts/Contacts";
// import NotFound from "./components/Body/NotFound/NotFound";

const App = () => {
    const dispatch = useAppDispatch()

    // Выгрузка данных в redux (shopBag) из localStorage, если они есть
    dispatch(clearItems())
    const dataString: string | null = localStorage.getItem('bag')

    if (dataString !== null) {
        // Проверка на валидный "bag" в localStorage
        try {
            const dataArray = JSON.parse(dataString)
            dispatch(concatItems(dataArray))
        } catch {
            dispatch(clearItems())
            localStorage.removeItem("bag")
        }
    }

    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path='' element={<Body/>}/>
                <Route path='/shopping-cart' element={<ShoppingCart/>}/>
                {/*<Route path='*' element={<NotFound/>}/>*/}
                {/*<Route path='/contacts' element={<Contacts/>}/>*/}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App