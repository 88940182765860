import c from "./Card.module.css";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardTitle,
    MDBRipple
} from "mdb-react-ui-kit";
import {useAppDispatch} from "../../../redux/hooks";
import {active} from "../../../redux/reduxComponents/modalWindow";
import {Product} from "../Body";

const Card = ({data, setActualProductCallback}: {
    data: Product,
    setActualProductCallback: Function
}) => {
    const dispatch = useAppDispatch()

    return (
        <MDBCard className={c.Item} onClick={() => {
            dispatch(active())
            setActualProductCallback(data)
        }}>
            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                <MDBCardImage src={`data:image/png;base64,${data['images_base64'][0]}`} fluid alt='...'/>
                <a>
                    <div className='mask' style={{backgroundColor: 'rgba(251, 251, 251, 0.15)'}}/>
                </a>
            </MDBRipple>
            <MDBCardBody style={{display: "flex", flexDirection: "column", justifyContent: 'space-between'}}>
                <div style={{ paddingBottom: '10px' }}>
                    <MDBCardTitle className={c.Title}> {data['item_name']} </MDBCardTitle>
                    <div className={c.Cost}>
                        {data['price']} ₽
                    </div>
                </div>
                <div style={{marginTop: '10px'}}>
                    <MDBBtn
                        className={c.MakeOrder}
                        color='dark'
                    >
                        Заказать
                    </MDBBtn>
                </div>
            </MDBCardBody>
        </MDBCard>
    );
};

export default Card;