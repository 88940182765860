import {useEffect, useState} from 'react';
import s from './ShoppingCart.module.css'
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBInput,
    MDBRow, MDBSpinner,
    MDBTypography,
} from "mdb-react-ui-kit";
import {Product} from "../Body";
import {clearItems, deleteItem} from "../../../redux/reduxComponents/shoppingBag";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {ToastContainer, toast} from 'react-toastify';

const ShoppingCart = () => {
    const [isLoadData, setIsLoadData] = useState<boolean>(false)
    const [productsData, setProductsData] = useState<Product[]>([])
    const [totalCost, setTotalCost] = useState(0)
    const [name, setName] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [deliveryAddress, setDeliveryAddress] = useState<string>('')

    const dispatch = useAppDispatch()
    const shoppingItems = useAppSelector(state => state.shoppingBag.items)

    const getProducts = () => {
        let temp_ids: number[] = []
        shoppingItems.map(item => (
            temp_ids.push(item.product_id)
        ))

        let body = {
            'product_ids': temp_ids
        }

        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(body)
        }

        fetch(`${process.env.REACT_APP_API}/get_products_c`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setProductsData(data['data'])
                setIsLoadData(true)
            })
            .catch(error => {
                throw new Error(error)
            })
    }

    const getProduct = (productId: number) => {
        let res =  productsData.filter(val => val['id'] === productId)[0]
        
        if (!res) {
            clearData()

            let emptyProduct: Product = {
                'id': 0,
                'item_name': '',
                'description': '',
                'vendor_code': '',
                'price': 0,
                'sizes': [0],
                'quantity': 0,
                'is_active': false,
                'images_base64': ['']
            }

            return emptyProduct
        }

        return res
    }

    const deleteProduct = (uuid: string) => {
        const data: string | null = localStorage.getItem('bag')
        if (data) {
            dispatch(deleteItem(uuid))
            let parsedData: any[] = JSON.parse(data)
            let order = parsedData.filter(item => item['uuid'] !== uuid)
            localStorage.setItem('bag', JSON.stringify(order))
        }
    }

    const clearData = () => {
        localStorage.removeItem('bag')
        dispatch(clearItems())
        setName('')
        setDeliveryAddress('')
        setPhoneNumber('')
    }

    const saveOrder = () => {
        if (name.length < 3 || phoneNumber.length < 5 || deliveryAddress.length < 5 || shoppingItems.length === 0) {
            return
        }

        let body = {
            'customer': {
                'name': name,
                'phone_number': phoneNumber,
                'delivery_address': deliveryAddress
            }, 'order_items': shoppingItems
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(body)
        }

        fetch(`${process.env.REACT_APP_API}/save_order`, requestOptions)
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(`Create order error, status: ${response.status}`)
                }
            })
            .then(_ => {
                toast.success('Ваш заказ успешно создан!')
                clearData()
            })
            .catch((error) => {
                toast.error('Ошибка создания заказа!')
                console.error(error)
            })
    }

    useEffect(() => {
        if (shoppingItems.length > 0) {
            getProducts()
        } else {
            setIsLoadData(true)
        }
    }, [])

    useEffect(() => {
        if (shoppingItems.length > 0) {
            let tempTotalCost: number = 0

            shoppingItems.map(val => (
                tempTotalCost += val['price'] * val['amount']
            ))
            setTotalCost(tempTotalCost)
        } else {
            setTotalCost(0)
        }
    }, [shoppingItems])

    return (
        <div className={s.Main}>
            <ToastContainer/>
            {
                isLoadData ?
                    <MDBContainer className="py-5 h-100">
                        <MDBRow className="justify-content-center align-items-center h-100">
                            <MDBCol size="12">
                                <MDBCard className="card-registration card-registration-2"
                                         style={{borderRadius: "15px"}}>
                                    <MDBCardBody className="p-0">
                                        <MDBRow className="g-0">
                                            <MDBCol lg="8">
                                                <div className="p-5">
                                                    <div className="d-flex justify-content-between align-items-center mb-5">
                                                        <MDBTypography tag="h2" className="fw-bold mb-0 text-black">
                                                            Корзина
                                                        </MDBTypography>
                                                    </div>
                                                    <hr className="my-4"/>
                                                    {
                                                        shoppingItems.length > 0 ?
                                                            shoppingItems.map((item, idx) => (
                                                                <div key={idx}>
                                                                    <MDBRow
                                                                        className="mb-4 d-flex justify-content-between align-items-center">
                                                                        <MDBCol md="2" lg="2" xl="2">
                                                                            <MDBCardImage
                                                                                src={`data:image/png;base64,${getProduct(item['product_id'])['images_base64']}`}
                                                                                fluid
                                                                                // className="rounded-3"
                                                                                className={s.Image}
                                                                                alt="image"
                                                                            />
                                                                        </MDBCol>
                                                                        <MDBCol md="3" lg="3" xl="3">
                                                                            <MDBTypography tag="h6" className="text-muted">
                                                                                {getProduct(item['product_id'])['item_name']}
                                                                            </MDBTypography>
                                                                            <MDBTypography tag="h6" className="text-black mb-0">
                                                                                Размер: {item['size']}
                                                                            </MDBTypography>
                                                                        </MDBCol>
                                                                        <MDBCol
                                                                            md="3" lg="3" xl="3"
                                                                            className="d-flex align-items-center"
                                                                        >
                                                                            <MDBTypography tag="h6" className="text-black mb-0">
                                                                                Количество пар: {item['amount']}
                                                                            </MDBTypography>
                                                                            <MDBBtn color="link" className="px-2">
                                                                                <MDBIcon fas icon="plus"/>
                                                                            </MDBBtn>
                                                                        </MDBCol>
                                                                        <MDBCol md="3" lg="2" xl="2"
                                                                                className="text-end">
                                                                            <MDBTypography tag="h6" className="mb-0">
                                                                                ₽ {getProduct(item['product_id'])['price'] * item['amount']}
                                                                            </MDBTypography>
                                                                        </MDBCol>
                                                                        <MDBBtn
                                                                            onClick={_ => deleteProduct(item['uuid'])}
                                                                            color="none"
                                                                            aria-label='Close'
                                                                            className="btn-close px-3"
                                                                        />
                                                                        <MDBCol md="1" lg="1" xl="1" className="text-end">
                                                                            <a href="#!" className="text-muted">
                                                                                <MDBIcon fas icon="times"/>
                                                                            </a>
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                    <hr className="my-4"/>
                                                                </div>
                                                            ))
                                                            :
                                                            'Корзина пуста'
                                                    }
                                                </div>
                                            </MDBCol>
                                            <MDBCol lg="4" className="bg-grey">
                                                <div className="p-5">
                                                    <MDBTypography tag="h3" className="fw-bold mb-5 mt-2 pt-1">
                                                        Оформление
                                                    </MDBTypography>
                                                    <hr className="my-4"/>
                                                    <div className="d-flex justify-content-between mb-4">
                                                        <MDBTypography tag="h5" className="text-uppercase">
                                                            Позиций: {shoppingItems.length}
                                                        </MDBTypography>
                                                    </div>
                                                    <MDBTypography tag="h5" className={`${s.Name} text-uppercase mb-3`}>
                                                        Имя
                                                    </MDBTypography>
                                                    <div className="mb-5">
                                                        <MDBInput
                                                            value={name}
                                                            onChange={e => setName(e.target.value)}
                                                            size="lg"
                                                            label="Введите имя"
                                                        />
                                                    </div>
                                                    <MDBTypography tag="h5" className={`${s.Phone} text-uppercase mb-3`}>
                                                        Телефон
                                                    </MDBTypography>
                                                    <div className="mb-5">
                                                        <MDBInput
                                                            type='tel'
                                                            size="lg"
                                                            label="Введите телефон"
                                                            value={phoneNumber}
                                                            onChange={e => setPhoneNumber(e.target.value)}
                                                        />
                                                    </div>
                                                    <MDBTypography tag="h5" className={`${s.Address} text-uppercase mb-3`}>
                                                        Адрес доставки
                                                    </MDBTypography>
                                                    <div className="mb-5">
                                                        <MDBInput
                                                            size="lg"
                                                            label="Введите адрес"
                                                            value={deliveryAddress}
                                                            onChange={e => setDeliveryAddress(e.target.value)}
                                                        />
                                                    </div>
                                                    <hr className="my-4"/>
                                                    <div className="d-flex justify-content-between mb-5">
                                                        <MDBTypography tag="h5" className="text-uppercase">
                                                            Итого
                                                        </MDBTypography>
                                                        <MDBTypography tag="h5">
                                                            ₽ {totalCost}
                                                        </MDBTypography>
                                                    </div>
                                                    <MDBBtn
                                                        onClick={_ => saveOrder()}
                                                        color="dark"
                                                        block
                                                        size="lg"
                                                    >
                                                        Создать заказ
                                                    </MDBBtn>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    :
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5.5%'}}>
                        <MDBSpinner role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                    </div>
            }
        </div>
    );
};

export default ShoppingCart;