import {useEffect, useState} from 'react';
import Slider from "./Slider/Slider";
import Card from './Card/Card'
import CardInfo from "./Card/CardInfo/CardInfo";
import b from './Body.module.css'
import {
    MDBBtn,
    MDBSpinner
} from 'mdb-react-ui-kit';
import {useAppSelector} from "../../redux/hooks";
import FixedHeader from "../FixedHeader/FixedHeader";

export interface Product {
    id: number
    item_name: string
    description: string
    vendor_code: string
    price: number
    sizes: number[]
    quantity: number
    is_active: boolean
    images_base64: string[]
}

const Body = () => {
    const modalState: boolean = useAppSelector(state => state.modalState.value)

    const [isScrolled, setIsScrolled] = useState(false)
    const [isLoadData, setIsLoadData] = useState<boolean>(false)
    const [productsData, setProductsData] = useState<Product[]>([])
    const [actualProduct, setActualProduct] = useState<Product | null>(null)
    const [page, setPage] = useState<number>(0)
    const [productsIsOver, setProductsIsOver] = useState<boolean>(false)

    const getProducts = () => {
        let body = {
            'page': page
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(body)
        };

        fetch(`${process.env.REACT_APP_API}/get_products`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['data'].length === 0) {
                    setProductsIsOver(true)
                }

                setProductsData(productsData.concat(data['data']))
                setIsLoadData(true)
            })
            .catch(error => {
                throw new Error(error)
            })
    }

    useEffect(() => {
        getProducts()
    }, [page])

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset
            if (scrollTop > 80) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        };
    }, [])

    return (
        <div className={b.MainBody}>
            {
                isScrolled &&
                <FixedHeader/>
            }
            <Slider/>
            {/*<div className={b.BottomLogo}>*/}
            {/*    SNEAKER &nbsp;&nbsp; SHOP*/}
            {/*</div>*/}
            <div className={b.BorderLine}/>
            <div className={b.NewGoods}>
                НОВИНКИ
            </div>
            <div className={b.Items}>
                {
                    isLoadData && productsData.length > 0 ?
                        productsData.map((data, index) => (
                            <Card
                                key={index}
                                data={data}
                                setActualProductCallback={setActualProduct}
                            />
                        ))
                        :
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5.5%'}}>
                            <MDBSpinner role='status'>
                                <span className='visually-hidden'>Loading...</span>
                            </MDBSpinner>
                        </div>
                }
            </div>
            {
                !productsIsOver &&
                    <div className={b.LoadButtonDiv}>
                        <MDBBtn
                            className="btn btn-outline-dark"
                            style={{fontWeight: 600, border: '2px solid #cccccc', color: '#a2a2a2', letterSpacing: '1px'}}
                            onClick={() => setPage(page + 1)}
                        >
                            Показать еще...
                        </MDBBtn>
                    </div>
            }
            <div className={b.BorderLine} style={{marginTop: '70px'}}/>
            {
                modalState && actualProduct && <CardInfo data={actualProduct}/>
            }
        </div>
    )
}

export default Body