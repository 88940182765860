import React, {useState} from 'react';
import c from './CardInfo.module.css'
import {MDBBtn} from "mdb-react-ui-kit";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {disable} from "../../../../redux/reduxComponents/modalWindow";
import {Carousel} from "react-responsive-carousel";
import uuid from 'react-uuid';
import Checkmark from '../../../../img/icons/checkmark.svg'
import {addItem, ShoppingItem} from "../../../../redux/reduxComponents/shoppingBag";
import {Product} from "../../Body";
import {ToastContainer, toast} from 'react-toastify';


const CardInfo = ({data}: { data: Product }) => {
    const [size, setSize] = useState<number>(data['sizes'][0])
    const [amount, setAmount] = useState<number>(1)
    const [isAdded, setIsAdded] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const shoppingItems = useAppSelector(state => state.shoppingBag.items)

    // Обработка нажатия на конкретно этот div без его потомков
    const handleDivClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        if (event.target === event.currentTarget) {
            dispatch(disable())
        }
    }

    const addToLocalStorage = (item: ShoppingItem) => {
        const dataString: string | null = localStorage.getItem('bag')

        if (dataString === null) {
            localStorage.setItem('bag', JSON.stringify([item]))
        } else {
            const dataArray = JSON.parse(dataString)
            dataArray.push(item)
            const modifiedDataArray = JSON.stringify(dataArray)
            localStorage.setItem('bag', modifiedDataArray)
        }
    }

    const addToBag = () => {
        if (shoppingItems.length > 9) {
            toast.error('Максимальное количество позиций в корзине - 10!')
            return
        }

        let item: ShoppingItem = {
            uuid: uuid(),
            product_id: data['id'],
            size: size,
            amount: amount,
            price: data['price']
        }

        setIsAdded(true)
        dispatch(addItem(item))
        addToLocalStorage(item)

        setTimeout(() => {
            dispatch(disable())
            setIsAdded(false)
        }, 1500)
    }

    return (
        <div className={c.Main} onClick={handleDivClick}>
            <ToastContainer/>
            <div className={c.Modal}>
                <div className={c.ProductTop}>
                    <div/>
                    <MDBBtn
                        className="btn-close"
                        style={{marginTop: '10px', marginRight: '10px'}}
                        color="none"
                        onClick={() => dispatch(disable())}
                    />
                </div>
                <div className={c.ProductBottom}>
                    <div className={c.Carousel}>
                        <Carousel
                            emulateTouch={true}
                            swipeable={true}
                            infiniteLoop={true}
                            // axis={"vertical"}

                            // animationHandler={"slide"}
                            // dynamicHeight={true}
                            // centerSlidePercentage={100}
                            // centerMode={true}
                        >
                            {
                                data["images_base64"].map((item, idx) => (
                                    <div key={idx}>
                                        <img alt="" src={`data:image/png;base64,${item}`}/>
                                    </div>
                                ))
                            }
                        </Carousel>
                    </div>
                    <div className={c.About}>
                        <div style={{textAlign: "center"}}>
                            <div className={c.Title}>
                                {data['item_name']}
                            </div>
                            <div className={c.Cost}>
                                {data['price']} ₽
                            </div>
                            <div className={c.Description}>
                                {data['description']}
                            </div>
                            <div className={`${c.SizeSelector} ${c.SizeSelectorTop}`}>
                                <div>
                                    Размер: &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className={c.CustomSelect}>
                                    <select
                                        value={size}
                                        // "+" перед "e.target.value" переводит string в number
                                        onChange={e => setSize(+e.target.value)}
                                    >
                                        {
                                            data['sizes']
                                                .sort((a, b) => (a - b))
                                                .map((item, idx) => (
                                                <option key={idx} value={item}> {item} </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className={c.SizeSelector}>
                                <div>
                                    Количество: &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className={c.CustomSelect}>
                                    <select
                                        value={amount}
                                        onChange={e => setAmount(+e.target.value)}
                                    >
                                        {
                                            data['quantity'] >= 4 ?
                                                [...Array(4)].map((_, idx) => (
                                                    <option key={idx} value={idx + 1}> {idx + 1} </option>
                                                ))
                                                :
                                                [...Array(data.quantity)].map((_, idx) => (
                                                    <option key={idx} value={idx + 1}> {idx + 1} </option>
                                                ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {
                                isAdded ?
                                    <MDBBtn
                                        className={c.Added}
                                        color='success'
                                    >
                                        <img className={c.Checkmark} src={Checkmark} alt=""/>Добавлено
                                    </MDBBtn>
                                    :
                                    <MDBBtn
                                        className={c.AddToBagButton}
                                        color='success'
                                        onClick={addToBag}
                                    >
                                        В корзину
                                    </MDBBtn>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardInfo