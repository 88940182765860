import {createSlice} from "@reduxjs/toolkit"

interface ModalState {
    value: boolean
}

const initialState: ModalState = {
    value: false
}

export const activeModal = createSlice({
    name: 'activeModal',
    initialState,
    reducers: {
        active: (state) => {
            state.value = true

            // скрытие вертикального скролла при открытии окна с выбором товара
            document.body.style.overflowY = 'hidden'
        },

        disable: (state) => {
            state.value = false

            // добавление вертикального скролла при закрытии окна с выбором товара
            document.body.style.overflowY = 'visible'
        }
    }
})

export const { active, disable } = activeModal.actions
// export const selectValue = (state: RootState) => state.modalState.value
export default activeModal.reducer