import {useState} from 'react'
import s from './Slider.module.css'
import photo1 from "../../../img/photos/1.jpg"
import photo2 from "../../../img/photos/2.jpg"
import photo3 from "../../../img/photos/3.jpg"
import photo4 from "../../../img/photos/4.jpg"
import {FaArrowAltCircleLeft, FaArrowAltCircleRight} from "react-icons/fa"

const Slider = () => {
    let slides: Array<{ image: string }> = [
        {
            image: photo1
        },
        {
            image: photo2
        },
        {
            image: photo3
        },
        {
            image: photo4
        }
    ]

    const [current, setCurrent] = useState(0)
    const length = slides.length

    const nextSlide = (): void => {
        setCurrent(current === length - 1 ? 0 : current + 1)
    }

    const prevSlide = (): void => {
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    if (!Array.isArray(slides) || slides.length <= 0) {
        return null
    }

    return (
        <div className={s.slider}>
            <FaArrowAltCircleLeft className={s.leftArrow} onClick={prevSlide}/>
            <FaArrowAltCircleRight className={s.rightArrow} onClick={nextSlide}/>
            {slides.map((slide, index: number) => {
                return (
                    <div
                        className={index === current ? s.slideActive : ''}
                        key={index}
                    >
                        {index === current && (
                            <img src={slide.image} alt='travel image' className={s.image}/>
                        )}
                    </div>
                )
            })}
        </div>
    );
};

export default Slider;