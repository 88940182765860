import {configureStore} from "@reduxjs/toolkit"
import activeModal from "./reduxComponents/modalWindow"
import shoppingBag from "./reduxComponents/shoppingBag"

export const store = configureStore({
    reducer: {
        modalState: activeModal,
        shoppingBag: shoppingBag
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch