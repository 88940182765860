import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ShoppingItem {
    uuid: string
    product_id: number
    size: number
    amount: number
    price: number
}

interface ShoppingBag {
    items: Array<ShoppingItem>
}

const initialState: ShoppingBag = {
    items: []
}

export const shoppingBag = createSlice({
    name: 'shoppingBag',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<ShoppingItem>) => {
            state.items.push(action.payload)
        },

        concatItems: (state, action: PayloadAction<ShoppingItem[]>) => {
            state.items = [...action.payload]
        },

        deleteItem: (state, action: PayloadAction<string>) => {
            state.items = state.items.filter(item => (
                    item.uuid !== action.payload
                )
            )
        },

        clearItems: (state) => {
            state.items = []
        }
    }
})

export const {
    addItem,
    deleteItem,
    clearItems,
    concatItems
} = shoppingBag.actions
// export const selectValue = (state: RootState) => state.
export default shoppingBag.reducer