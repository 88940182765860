import f from './FixedHeader.module.css'
import {useAppSelector} from "../../redux/hooks"
import Cart from "../../img/icons/shopping-cart.png"
import {NavLink} from "react-router-dom";

const FixedHeader = () => {
    const shoppingItems = useAppSelector(state => state.shoppingBag.items)

    return (
        <div className={f.Main}>
            <div>
                +7 900 444-33-88
            </div>
            <NavLink className={f.Cart} to='/shopping-cart'>
                <img height='20px' src={Cart} alt=""/>
                {
                    shoppingItems.length > 0 &&
                        <span className={f.CartCount}>{shoppingItems.length}</span>
                }
            </NavLink>
        </div>
    );
};

export default FixedHeader;