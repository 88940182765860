import {NavLink} from "react-router-dom";
import f from './Footer.module.css'

const Footer = () => {
    return (
        <div className={f.Footer}>
            <div className={f.LogoName}>
                SNEAKER SHOP
            </div>
            <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between'}}>
                <div>
                    <div className={f.InfoBoxes}>
                        <div className={f.MainInfo}>
                            Основное
                        </div>
                        <NavLink to='/'>
                            <button
                                className={f.Button}
                                style={{
                                    fontSize: '100%',
                                    paddingLeft: '0',
                                    paddingRight: '0',
                                    color: 'black'
                                }}
                            >
                                Товары
                            </button>
                        </NavLink>
                        <div className={f.Contacts} style={{marginTop: '1rem', color: 'rgba(28,28,28,0.45)'}}>
                            Контакты
                        </div>
                    </div>
                </div>
                <div>
                    <div className={f.InfoBoxes}>
                        <div className={f.AdditionalInfo}>
                            Дополнительно
                        </div>
                        {/*<div>*/}
                        {/*    О Проекте*/}
                        {/*</div>*/}

                        <div className={f.PayTypes} style={{marginTop: '1rem', color: 'rgba(28,28,28,0.45)'}}>
                            Способы оплаты
                        </div>
                        <div className={f.Delivery} style={{marginTop: '1rem', color: 'rgba(28,28,28,0.45)'}}>
                            Доставка
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className={f.YourQuestions}>
                            Ваши вопросы
                        </div>
                        <div className={f.Post}>
                            test@domain.com
                        </div>
                        <div style={{marginTop: '1rem', color: 'rgba(28,28,28,0.45)'}}>
                            Любая полезная информация и<br/>сотрудничество приветствуются
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;